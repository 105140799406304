import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledButton = styled(Button)`
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
`;

const InformationButton = ({ number, title, link }) => {
  return (
    <Link to={{ pathname: link, state: { fromNotifications: true } }}>
      <StyledButton elevation={1} style={{color:'black', fontWeight:700}} variant='contained' color="text.secondary">
        <span style={{ color: 'red', marginRight: '8px', fontWeight: 600 }}>{number}</span>
        {number === 1 ? title.slice(0, -1) : title}
      </StyledButton>
    </Link>
  );
};

export default InformationButton;

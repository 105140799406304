import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import { LatestOrders, InformationButton } from './components';
import { LeadsContext } from 'sharedState/authState';
import ApiManaging from './components/ApiManaging/ApiManaging';
import { getNumberOfNewOrders } from 'apiRequests';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: '100%',
  },
  informationCards: {
    maxHeight: 80,
  },
}));

const Dashboard = () => {
  const [numberOfNewOrders, setNumberOfNewOrders] = useState(0);
  const [leads] = React.useContext(LeadsContext);
  // const [apisStatus, setApisStatus] = useState();
  const newLeads = leads[1];

  useEffect(() => {
    // console.log('GETTING NEW ORDERS');
    getNumberOfNewOrders()
      .then(({ data }) => {
        // console.log('DA', data);
        setNumberOfNewOrders(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/**
       * Information section
       */}
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, sm: 3 }} className={classes.informationCards}>
          <InformationButton
            title={'new leads'}
            number={newLeads}
            link="/leads"
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }} className={classes.informationCards}>
          <InformationButton
            title={'new orders'}
            number={numberOfNewOrders}
            link="/orders"
          />
        </Grid>
        <Grid size={{ xs: 12, xl: 9, lg: 8 }}>
          <LatestOrders />
        </Grid>
      </Grid>
      {/**APIs status section */}
      <Grid container>
        <Grid size={{ lg: 4 }}>
          <ApiManaging />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;

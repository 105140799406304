const suppliers = [
  { name: 'addCar', code: 'addcar', image: 'https://i.imgur.com/6llNZMh.png' },
  { name: 'Avis', code: 'avis', image: 'https://i.imgur.com/jjfdVut.png' },
  {
    name: 'Enterprise',
    code: 'enterprise',
    image: 'https://i.imgur.com/YI1BZgY.png',
  },
  { name: 'Budget', code: 'budget', image: 'https://i.imgur.com/mFEmIiU.png' },
  {
    name: 'Europcar',
    code: 'europcar',
    image: 'https://i.imgur.com/8pilFd1.png',
  },
  {
    name: 'Active Car Rental',
    code: 'active',
    image: 'https://i.imgur.com/fRYEr3P.png',
  },
  {
    name: 'Top Rent A Car',
    code: 'toprentacar',
    image: 'https://i.imgur.com/AoJwHiS.jpg',
  },
  { name: 'Alamo', code: 'alamo', image: 'https://i.imgur.com/khr1qXI.png' },
  {
    name: 'Rent Plus',
    code: 'rentplus',
    image: 'https://i.imgur.com/gBZtlr0.png',
  },
  { name: 'Hertz', code: 'hertz', image: 'https://i.imgur.com/QSvOfBu.png' },
  { name: 'Sixt', code: 'sixt', image: 'https://i.imgur.com/4UHXVFl.png' },
  { name: 'Carwiz', code: 'carwiz', image: 'https://i.imgur.com/V9OstRj.png' },
  {
    name: 'Thrifty',
    code: 'thrifty',
    image: 'https://i.imgur.com/Mx3uDdO.jpeg',
  },
  { name: 'Guerin', code: 'guerin', image: 'https://i.imgur.com/ihQUq1U.png' },
  {
    name: 'Payless',
    code: 'payless',
    image: 'https://i.imgur.com/3PENcVB.png',
  },
  {
    name: 'Sicily By Car',
    code: 'sicilybycar',
    image: 'https://i.imgur.com/YfQ7oJg.png',
  },
  {
    name: 'Auto Union',
    code: 'autounion',
    image: 'https://i.imgur.com/1g9c2HG.png',
  },
];
export default suppliers;
